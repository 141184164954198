import { Image } from './Image';

export class Author {
  constructor(data) {
    this.data = data;
    this.slug = this.data?.slug;
    this.uuid = this.data?.uuid;
  }

  get name() {
    return this.data?.content?.name;
  }

  get bio() {
    return this.data?.content?.bio;
  }

  get profileImage() {
    return new Image(this.data?.content?.photo);
  }

  get socialLinks() {
    return Object.entries(this.data?.content ?? {})
      .filter(([key, value]) => key.includes('social_link_') && !!value)
      .map(([key, value]) => ({
        name: key.split('social_link_')[1],
        url: value,
      }));
  }
}
