import { PageAnnouncementModel, IStoryblokAnnouncement } from '~/models/PageAnnouncement';

interface ISelectAnnouncementProps {
  announcement: IStoryblokAnnouncement,
}

export function selectAnnouncement(props: ISelectAnnouncementProps) {
  return {
    announcement: new PageAnnouncementModel(props.announcement)
  };
}
