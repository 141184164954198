import { ref } from '@nuxtjs/composition-api';
import { useStoryblok } from './useStoryblok';

export function useFetchLayout() {
  const { storyblok } = useStoryblok();
  const layout = ref({
    announcement: {},
  });

  async function fetchLayout() {
    const resLayout = await storyblok.getLayout();
    const [announcement] = resLayout?.content?.announcement || [];

    layout.value.announcement = announcement;
  }

  return {
    layout,
    fetchLayout
  };
}
