import { Image } from './Image';

export class Topic {
  constructor(data = {}) {
    this.data = data;
  }

  get name() {
    return this.data?.content?.name;
  }

  get slug() {
    return this.data?.slug;
  }

  get uuid() {
    return this.data?.uuid;
  }

  get coverImage() {
    return new Image(this.data?.content?.cover_image);
  }

  get blockImage() {
    return new Image(this.data?.content?.block_image);
  }

  get route() {
    return `/t/${this.slug}`;
  }

  get metaInfo() {
    return {
      title: this.data?.content?.meta_info?.title,
      description: this.data?.content?.meta_info?.description,
    };
  }
}
