export default function netlifyFunctions({ $axios }, inject) {
  // Create a custom axios instance
  const netlify = $axios.create({
    headers: {
      common: {
        Accept: 'text/plain, */*'
      }
    }
  });

  netlify.setBaseURL('/.netlify/functions/');

  // Inject to context as $netlify
  inject('netlify', netlify);
}
