export class Image {
  constructor(data) {
    this.data = data;
  }

  get src() {
    return this.data?.filename;
  }

  get alt() {
    return this.data?.alt ?? 'FightCamp Article';
  }

  get focus() {
    return this.data?.focus;
  }

  get originalWidth() {
    const [width] = this.#getDimensionsFromUrl();
    return width;
  }

  get originalHeight() {
    const [, height] = this.#getDimensionsFromUrl();
    return height;
  }

  #getDimensionsFromUrl() {
    const imageSrc = new URL(this.src);
    return imageSrc.pathname.split('/')[3].split('x');
  }
}
