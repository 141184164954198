import { useContext } from '@nuxtjs/composition-api';
import { StoryblokService } from '~/services/StoryblokService';

export function useStoryblok() {
  const { $storyapi } = useContext();
  const version = process.env.NUXT_ENV_IS_DEV === 'true' ? 'draft' : 'published';

  const storyblok = new StoryblokService({
    storyapi: $storyapi,
    version
  });

  return { storyblok };
}
