import { BackgroundTheme } from '@fc/angie-ui';

export interface IStoryblokAnnouncement {
  active: boolean;
  theme: BackgroundTheme;
  text: string;
  headline: string;
  component: string;
  link_text: string;
  link_url: {
    url: string;
  }
}

export interface IAnnouncementCTA {
  url: string;
  text: string;
}

export class PageAnnouncementModel {
  active: boolean;

  component: string;

  headline: string;

  text: string;

  cta: IAnnouncementCTA;

  theme: BackgroundTheme;

  constructor(data: IStoryblokAnnouncement) {
    this.active = data?.active || false;
    this.theme = data?.theme || BackgroundTheme.red;
    this.component = data?.component || '';
    this.headline = data?.headline || '';
    this.text = data?.text || '';
    this.cta = {
      url: data?.link_url?.url || '',
      text: data?.link_text || '',
    };
  }
}
