import { Article } from '~/models/Article';
import { Category } from '~/models/Category';

export function isArticles(articles) {
  return articles.every(isArticle);
}

export function isArticle(article) {
  return article instanceof Article;
}

export function isCategory(categories) {
  return categories.every((cat) => cat instanceof Category);
}
