import StoryblokClient from 'storyblok-js-client';
import { getCurrentDate } from '../utils/helpers';

const Storyblok = new StoryblokClient({ accessToken: process.env.NUXT_ENV_STORYBLOK });
export class StoryblokService {
  constructor({ storyapi = Storyblok, version = 'draft' } = {}) {
    this.client = storyapi;
    this.version = version;
  }

  #get(url, params) {
    return this.client.get(url, {
      version: this.version,
      ...params
    });
  }

  async getLayout() {
    try {
      const res = await this.#get('cdn/stories/global');
      return res?.data?.story ?? {};
    } catch (err) {
      return err;
    }
  }

  async getCategories() {
    const res = await this.#get(
      'cdn/stories?starts_with=categories/',
      {
        resolve_relations: ['category.topics'],
        sort_by: 'position:desc'
      }
    );
    return res?.data?.stories ?? [];
  }

  async getCategory(categorySlug) {
    const res = await this.#get(
      `cdn/stories/categories/${categorySlug}`,
      {
        resolve_relations: ['category.topics'],
      }
    );
    return res?.data?.story ?? {};
  }

  async getTopic(topicSlug) {
    const res = await this.#get(
      `cdn/stories/topics/${topicSlug}`,
      {
        resolve_relations: ['topic.category'],
      }
    );
    return res?.data?.story ?? {};
  }

  async getAllArticles() {
    const requestParams = {
      starts_with: 'blogs',
      resolve_relations: ['article.topic', 'topic.category'],
      filter_query: {},
    };

    if (this.version === 'published') {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        schedule: {
          lt_date: getCurrentDate(),
        },
      };
    }

    const res = await this.client.getAll(
      'cdn/stories',
      requestParams
    );

    return res ?? [];
  }

  async getPaginatedArticles(options) {
    const {
      categoryUuid,
      tags,
      coverImg,
      limit,
      topicUuid,
      page,
      perPage,
      authorUuid,
    } = options || {};
    const requestParams = {
      resolve_relations: ['article.author', 'article.topic', 'topic.category'],
      sort_by: 'first_published_at:desc',
      filter_query: {},
    };

    if (this.version === 'published') {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        schedule: {
          lt_date: getCurrentDate(),
        },
      };
    }

    if (categoryUuid) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        category: { in: categoryUuid }
      };
    }

    if (authorUuid) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        author: { in: authorUuid }
      };
    }

    if (topicUuid) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        topic: { in: topicUuid }
      };
    }

    if (tags) {
      requestParams.with_tag = tags;
    }

    if (coverImg) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        'cover_image.id': {
          is: 'not_null'
        }
      };
    }

    if (page != null && perPage != null) {
      requestParams.page = page;
      requestParams.per_page = perPage;
    } else if (limit) {
      requestParams.per_page = limit;
    }

    const res = await this.#get(
      'cdn/stories?starts_with=blogs/',
      requestParams
    );
    const { data, perPage: _perPage, total } = res || {};

    return {
      data: data?.stories ?? [],
      perPage: _perPage ?? null,
      total: total ?? null,
      canFetchMore: total > _perPage * (page || 1)
    };
  }

  async getArticles({
    categoryUuid, tags, coverImg, limit, topicUuid
  } = {}) {
    const requestParams = {
      resolve_relations: ['article.topic', 'topic.category'],
      sort_by: 'first_published_at:desc',
      filter_query: {},
    };

    if (this.version === 'published') {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        schedule: {
          lt_date: getCurrentDate(),
        },
      };
    }

    if (categoryUuid) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        category: { in: categoryUuid }
      };
    }

    if (topicUuid) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        topic: { in: topicUuid }
      };
    }

    if (tags) {
      requestParams.with_tag = tags;
    }

    if (coverImg) {
      requestParams.filter_query = {
        ...requestParams.filter_query,
        'cover_image.id': {
          is: 'not_null'
        }
      };
    }

    if (limit) {
      requestParams.per_page = limit;
    }

    const res = await this.#get(
      'cdn/stories?starts_with=blogs/',
      requestParams
    );
    return res?.data?.stories ?? [];
  }

  async getArticle(articleName) {
    const res = await this.#get(
      `cdn/stories/blogs/${articleName}`,
      {
        resolve_links: 'url',
        resolve_relations: [
          'article.contributors',
          'article.author',
          'article.topic',
          'topic.category',
        ]
      }
    );
    return res?.data?.story ?? {};
  }

  async getAuthor(slug) {
    const res = await this.#get(`cdn/stories/authors/${slug}`);
    return res?.data?.story ?? {};
  }

  async getPage(pageSlug) {
    const res = await this.#get(
      `cdn/stories/pages/${pageSlug}`,
      {
        // TODO how to resolve relations per page?
        resolve_relations: ['categories_topics_list.categories_topics']
      }
    );
    return res?.data?.story ?? {};
  }
}
