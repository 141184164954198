import { Topic } from './Topic';
import { Category } from './Category';
import { Author } from './Author';
import { Image } from './Image';

export class Article {
  constructor(data) {
    this.data = data;
    this.contributors = (this.data?.content?.contributors ?? [])
      .map(contributor => new Author(contributor));
  }

  get uuid() {
    return this.data?.uuid;
  }

  get slug() {
    return this.data?.slug;
  }

  get fullSlug() {
    return `/${this.category.slug}/${this.slug}`;
  }

  get fullUrl() {
    return `${process.env.NUXT_ENV_BASE_URL}${this.fullSlug}`;
  }

  get title() {
    return this.data?.content?.title;
  }

  get coverImg() {
    return new Image(this.data?.content?.cover_image);
  }

  get description() {
    return this.data?.content?.description;
  }

  get publishedAt() {
    return this.data?.published_at;
  }

  get firstPublishedAt() {
    return this.data?.first_published_at;
  }

  get schedule() {
    return this.data?.content?.schedule;
  }

  get content() {
    return this.data?.content?.content;
  }

  get metaInfo() {
    return {
      title: this.data?.content?.meta_info?.title,
      description: this.data?.content?.meta_info?.description,
    };
  }

  get tags() {
    return this.data?.tag_list ?? [];
  }

  get faqs() {
    return this.data?.content?.faqs?.map(block => ({
      question: block.question,
      answer: block.answer
    })) ?? [];
  }

  get category() {
    return new Category(this.data?.content?.topic?.content?.category);
  }

  get topic() {
    return new Topic(this.data?.content?.topic);
  }

  get author() {
    return new Author(this.data?.content?.author);
  }
}
