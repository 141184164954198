import { defineComponent } from '@nuxtjs/composition-api';
import pick from 'lodash/pick';

export function WithSelector(ComponentToWrap, selector, options = {}) {
  const modifiedPropEntries = Object.entries({
    ...(ComponentToWrap.props || {})
  }).map(([propName, propType]) => {
    if (propType) {
      return [
        propName,
        {
          ...propType,
          required: false,
          default: undefined,
          validator: undefined,
        }
      ];
    }

    return [propName, propType];
  });

  return defineComponent({
    name: `Wrapped${ComponentToWrap.name}`,
    props: {
      ...Object.fromEntries(modifiedPropEntries),
      ...(options?.propTypesOverride ?? {})
    },
    render(createElement) {
      return createElement(ComponentToWrap, {
        on: {
          renderNext: () => {
            this.$emit('fetch-next');
          }
        },
        props: {
          ...pick(this.$props, Object.keys(ComponentToWrap.props)),
          ...selector(this.$props),
        },
      });
    },
  });
}
