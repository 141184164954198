import { ref } from '@nuxtjs/composition-api';
import { useStoryblok } from './useStoryblok';

export function useFetchCategories() {
  const { storyblok } = useStoryblok();
  const rawCategoriesData = ref([]);

  async function fetchCategories() {
    rawCategoriesData.value = await storyblok.getCategories();
  }

  return {
    rawCategoriesData,
    fetchCategories
  };
}
