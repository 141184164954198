import { Image } from './Image';
import { Topic } from './Topic';

export class Category {
  constructor(data = {}) {
    this.data = data;
  }

  get name() {
    return this.data?.content?.name;
  }

  get coverImage() {
    return new Image(this.data?.content?.cover_image);
  }

  get blockImage() {
    return new Image(this.data?.content?.block_image);
  }

  get uuid() {
    return this.data?.uuid;
  }

  get slug() {
    return this.data?.slug;
  }

  get topics() {
    return this.data?.content?.topics?.map(topic => new Topic(topic) ?? []);
  }

  get route() {
    return `/c/${this.slug}`;
  }

  get metaInfo() {
    return {
      title: this.data?.content?.meta_info?.title,
      description: this.data?.content?.meta_info?.description,
    };
  }
}
